
import { defineComponent } from 'vue';
import { Route } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
import { Names } from '@/plugins/vue-router';

interface IMenuItem {
  title: string;
  to: { name: Names };
  icon: string;
  testLabel: string;
  show: boolean;
  target?: '_blank';
  position?: 'append' | 'prepend' | 'default';
}

interface IAppAsideLeftData {
  routerName: Route['name'];
  prevRoute: Route;
}

const userStore = createNamespacedHelpers('user');
const companyStore = createNamespacedHelpers('company');

export default defineComponent({
  name: 'AppAsideLeft',

  inject: ['Names'],
  data(): IAppAsideLeftData {
    return {
      routerName: this.$router.currentRoute.name,
      prevRoute: {} as Route,
    };
  },

  computed: {
    ...userStore.mapGetters(['hasChiefRole']),
    ...companyStore.mapGetters(['companyId']),
    menuItems(): IMenuItem[][] {
      return [
        [
          {
            title: this.$t('AppAsideLeft.dashboard'),
            to: { name: this.Names.R_APP_LEARNING },
            icon: 'fal fa-graduation-cap',
            testLabel: 'learning',
            show: true,
          },
          {
            title: this.$t('AppAsideLeft.profile'),
            to: { name: this.Names.R_APP_PROFILE },
            icon: '$user',
            testLabel: 'profile',
            show: true,
          },
          {
            title: this.$t('AppAsideLeft.analytics'),
            to: { name: this.Names.R_APP_MANAGER },
            icon: '$analytics',
            testLabel: 'analytics',
            show: this.hasChiefRole,
          },
          {
            title: this.$t('AppAsideLeft.items.wiki'),
            to: { name: this.Names.R_EXTERNAL_WIKI },
            icon: 'fal fa-question-circle',
            position: 'append' as const,
            target: '_blank' as const,
            testLabel: 'wiki',
            show: true,
          },
        ].filter((page) => page.show === true),
      ];
    },
    analyzedPages(): string[] {
      return [
        this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_VIEW,
        this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT,
      ];
    },
  },

  watch: {
    $route(to, from) {
      this.routerName = to.name;
      this.prevRoute = from;
    },
  },

  methods: {
    async sendAnalytic(item: IMenuItem) {
      if (this.analyzedPages.includes(this.prevRoute.name!)) {
        const data = {
          companyId: this.companyId,
          playerSessionId: this.prevRoute.params.playerSessionId,
          stepId: this.prevRoute.params.stepId,
          trackSessionId: this.prevRoute.params.trackSessionId,
        };
        await this.$di.tmt.sendEvent('send', 'click', item.title, 'left-sidebar-link', 'internallLink', data);
      }
    },
  },
});
